import { createIcon } from "@chakra-ui/icons";

const Speedometer03 = createIcon({
  displayName: "Speedometer03",
  viewBox: "0 0 24 24",
  path: [
    <path
      d="M5 12C5 8.13401 8.13401 5 12 5M16.4999 7.5L11.9999 12M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12ZM13 12C13 12.5523 12.5523 13 12 13C11.4477 13 11 12.5523 11 12C11 11.4477 11.4477 11 12 11C12.5523 11 13 11.4477 13 12Z"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      key="UBfU"
    ></path>,
  ],
  defaultProps: { boxSize: 4 },
});

export default Speedometer03;
