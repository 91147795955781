import { createIcon } from "@chakra-ui/icons";

const CurrencyEthereum = createIcon({
  displayName: "CurrencyEthereum",
  viewBox: "0 0 24 24",
  path: [
    <path
      d="M4 11.0001L12 13L20 11M4 11.0001L12 2M4 11.0001L12 9.00008M20 11L12 2M20 11L12 9.00008M12 2V9.00008M5.5 15L12.0001 22L18.5 15L12 16.5L5.5 15Z"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      key="ZIOq"
    ></path>,
  ],
  defaultProps: { boxSize: 4 },
});

export default CurrencyEthereum;
