import { createIcon } from "@chakra-ui/icons";

const Download01 = createIcon({
  displayName: "Download01",
  viewBox: "0 0 24 24",
  path: [
    <path
      d="M21 15V16.2C21 17.8802 21 18.7202 20.673 19.362C20.3854 19.9265 19.9265 20.3854 19.362 20.673C18.7202 21 17.8802 21 16.2 21H7.8C6.11984 21 5.27976 21 4.63803 20.673C4.07354 20.3854 3.6146 19.9265 3.32698 19.362C3 18.7202 3 17.8802 3 16.2V15M17 10L12 15M12 15L7 10M12 15V3"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      key="tos2"
    ></path>,
  ],
  defaultProps: { boxSize: 4 },
});

export default Download01;
