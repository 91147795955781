import { createIcon } from "@chakra-ui/icons";

const Database01 = createIcon({
  displayName: "Database01",
  viewBox: "0 0 24 24",
  path: [
    <path
      d="M21 5C21 6.65685 16.9706 8 12 8C7.02944 8 3 6.65685 3 5M21 5C21 3.34315 16.9706 2 12 2C7.02944 2 3 3.34315 3 5M21 5V19C21 20.66 17 22 12 22C7 22 3 20.66 3 19V5M21 12C21 13.66 17 15 12 15C7 15 3 13.66 3 12"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      key="NbQN"
    ></path>,
  ],
  defaultProps: { boxSize: 4 },
});

export default Database01;
