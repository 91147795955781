import { createIcon } from "@chakra-ui/icons";

const UserX01 = createIcon({
  displayName: "UserX01",
  viewBox: "0 0 24 24",
  path: [
    <path
      d="M16.5 16L21.5 21M21.5 16L16.5 21M12 15.5H7.5C6.10444 15.5 5.40665 15.5 4.83886 15.6722C3.56045 16.06 2.56004 17.0605 2.17224 18.3389C2 18.9067 2 19.6044 2 21M14.5 7.5C14.5 9.98528 12.4853 12 10 12C7.51472 12 5.5 9.98528 5.5 7.5C5.5 5.01472 7.51472 3 10 3C12.4853 3 14.5 5.01472 14.5 7.5Z"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      key="evk1"
    ></path>,
  ],
  defaultProps: { boxSize: 4 },
});

export default UserX01;
