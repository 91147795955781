import { createIcon } from "@chakra-ui/icons";

const Magnet = createIcon({
  displayName: "Magnet",
  viewBox: "0 0 24 24",
  path: [
    <path
      d="M 19.504983,4.5176541 C 17.87262,2.8549106 15.707242,1.9237742 13.408609,1.9237742 c -2.165378,0 -4.1975029,0.7981169 -5.7299246,2.3278409 L 2.5484026,9.4393749 c -0.8328379,0.8313721 -0.8328379,2.1948221 0,3.0261931 l 1.9654976,1.962037 c 0.8328378,0.831372 2.1986922,0.831372 3.0315302,0 L 12.37589,9.6389042 c 0.599643,-0.5985877 1.532422,-0.6983525 2.098752,-0.2327843 0.299822,0.2327843 0.466389,0.5985881 0.499703,0.9976461 0.03331,0.465568 -0.166568,0.897882 -0.499703,1.230431 l -4.83046,4.821956 c -0.8328381,0.831372 -0.8328381,2.194821 0,3.026193 l 1.965498,1.962037 c 0.399761,0.399058 0.966092,0.631843 1.499108,0.631843 0.533016,0 1.099346,-0.19953 1.499108,-0.631843 l 5.163596,-5.154505 C 22.936275,13.130666 22.836334,7.8763962 19.504983,4.5176541 Z M 6.4793979,13.330194 c -0.2331948,0.232785 -0.6662703,0.232785 -0.8994651,0 L 3.6144352,11.368158 c -0.2331945,-0.232785 -0.2331945,-0.665098 0,-0.897882 L 5.3134247,8.7742775 8.178387,11.634197 Z m 7.0624651,7.050033 c -0.233195,0.232785 -0.66627,0.232785 -0.899464,0 l -1.965498,-1.962036 c -0.233195,-0.232785 -0.233195,-0.665098 0,-0.897883 l 1.698989,-1.695998 2.864963,2.859919 z m 5.196909,-5.154505 -2.398573,2.394352 -2.864962,-2.859919 2.098751,-2.095058 c 0.632957,-0.631842 0.966091,-1.496469 0.932779,-2.361096 C 16.473453,9.47263 16.07369,8.7410225 15.440734,8.2089446 14.907717,7.7766311 14.274761,7.577102 13.608491,7.577102 c -0.832838,0 -1.665677,0.3325489 -2.298634,0.9643913 L 9.2777332,10.570041 6.4127708,7.7101218 8.8113439,5.3157711 C 10.043944,4.1185957 11.676306,3.4202433 13.441923,3.4202433 c 0,0 0.03331,0 0.03331,0 1.89887,0 3.664487,0.7648621 5.030341,2.1615665 2.665081,2.7601543 2.798335,7.0832872 0.233195,9.6439122 z"
      id="path1"
      stroke="currentColor"
      fill="currentColor"
      strokeWidth="0.332843"
      key="JbUZ"
    ></path>,
  ],
  defaultProps: { boxSize: 4 },
});

export default Magnet;
