import { createIcon } from "@chakra-ui/icons";

const Film02 = createIcon({
  displayName: "Film02",
  viewBox: "0 0 24 24",
  path: [
    <path
      d="M12 22V2M7 22V17M7 7V2M17 22V17M17 7V2M2 7H22M2 17H22M22 17.2V6.8C22 5.11984 22 4.27976 21.673 3.63803C21.3854 3.07354 20.9265 2.6146 20.362 2.32698C19.7202 2 18.8802 2 17.2 2L6.8 2C5.11984 2 4.27976 2 3.63803 2.32698C3.07354 2.6146 2.6146 3.07354 2.32698 3.63803C2 4.27976 2 5.11984 2 6.8L2 17.2C2 18.8802 2 19.7202 2.32698 20.362C2.6146 20.9265 3.07354 21.3854 3.63803 21.673C4.27976 22 5.11984 22 6.8 22H17.2C18.8802 22 19.7202 22 20.362 21.673C20.9265 21.3854 21.3854 20.9265 21.673 20.362C22 19.7202 22 18.8802 22 17.2Z"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      key="mK7k"
    ></path>,
  ],
  defaultProps: { boxSize: 4 },
});

export default Film02;
